<template>
  <div>
    <b-modal
      :id="tabAgendamento.id"
      ref="tabela-agendamento"
      :title="tabAgendamento.title"
      size="xl"
      hide-footer
    > <b-row>
        <b-col>
          <!-- Table Agendamentos -->
          <b-table
            :items="itemsTabelaAgend"
            :fields="fieldsTabelaAgend"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <!-- coluna actions botões -->
            <template #cell(actions)="row">

              <!-- Executar Button -->

              <b-button
                size="sm"
                @click.prevent="deleteAgendamento(row.item, $event.target)"
              >
                Excluir
              </b-button>
            </template>

            <template #row-details="row">
              <b-container>
                <b-row>
                  <b-col>
                    <b-table
                      :current-page="currentPage2"
                      :fields="fieldsInfoCods"
                      :items="itemsInfoCods"
                      :per-page="perPage"
                    />
                    <b-pagination

                      :total-rows="rows"
                      :per-page="perPage2"
                      align="center"
                      size="sm"
                    />
                  </b-col>

                </b-row>
              </b-container>
            </template>

          </b-table>
        </b-col>
        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            size="sm"
          />
        </b-col>
      </b-row>
      <b-button
        variant="outline-primary"
        @click="modalAgendamento($event.target)"
      >
        Novo Agendamento
      </b-button>
    </b-modal>
    <modal-agendamento ref="modAgendamento" />
  </div>
</template>
<script>
import axios from 'axios'
import ModalAgendamento from '@/views/processamento/ModalAgendamento.vue'
import {
  BPagination, BButton, BCol, BTable, BRow,
} from 'bootstrap-vue'

export default {
  name: 'ModalTabelaAgend',
  components: {
    ModalAgendamento,
    BPagination,
    BRow,
    BCol,
    BTable,
    BButton,
  },
  data() {
    return {
      agendamento: {
        id: 'agendamento',
        title: 'Agendamento',
        content: '',
      },
      tabAgendamento: {
        id: 'tabAgendamento',
        title: '',
        content: '',
      },
      currentPage: 1,
      currentPage2: 1,
      perPage: 10,
      itemsTabelaAgend: [],

      fieldsTabelaAgend: [
        { key: 'safx.table_name', label: 'Interface' },
        { key: 'safx.desc', label: 'Descrição' },
        { key: 'frequency', label: 'Frequência' },
        { key: 'actions', label: 'Actions' },
      ],

    }
  },
  computed: {
    rows() {
      return this.itemsTabelaAgend.length
    },
  },

  methods: {
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    modalAgendamento(button) {
      this.show = true // loading page b-overlay
      const titleValue = this.tabAgendamento.title
      console.log(titleValue)
      this.$refs.modAgendamento.agendamento.title = titleValue
      this.$refs.modAgendamento.getEmpresaAg()
      this.$refs.modAgendamento.getSAFX()

      this.$root.$emit('bv::show::modal', this.$refs.modAgendamento.agendamento.id, button) // Faz o modal aparecer
      this.show = false
    },

    async agTable() {
      this.show = true // loading page b-overlay
      this.isBusy = true
      try {
        const selectedCnpj = this.tabAgendamento.title
        if (selectedCnpj === null) {
          this.toast('b-toaster-top-right', true, 'danger', 'Selecione um cliente!', 'Erro de usabilidade')
          throw new Error('Selecione um cliente!')
        }

        const res = await axios.get(`/scheduler/all/${selectedCnpj}`)

        console.log(res.data)
        this.itemsTabelaAgend = res.data.map(item => {
          const cronArray = item.frequency.split(' ')
          const hourField = cronArray[1]
          const frequencyField = cronArray[4]
          const frequencyText = frequencyField === '*' ? ' diariamente' : frequencyField
          const formattedCron = `${hourField} hrs, ${frequencyText}`

          return {
            ...item,
            frequency: formattedCron,
          }
        })
        this.totalRows = this.itemsTabelaAgend.length
        this.currentPage = 1

        console.log(res.data)
      } catch (error) {
        console.log(error)
      }
      this.isBusy = false
      this.show = false
    },

    async deleteAgendamento(item) {
      const safx = item.id_safx_fk
      try {
        console.log(safx)
        console.log(this.tabAgendamento.title)
        await axios.delete(`/scheduler/${this.tabAgendamento.title}/${safx}`)
        this.toast('b-toaster-top-right', true, 'success', 'Agendamento excluído com sucesso!', 'Regra Deletada')
        // "this.toast"onde chama o metodo que esta acima qndo se clica no botão.
        this.agTable()
        // this.hideModal2()
      } catch (error) {
        console.log(error)
        this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
        if (error.response.status === 500) {
          this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
        } else {
          this.toast('b-toaster-top-right', true, 'warning', `Regra ${this.modalDeleteRule.title} não encontrada`, 'Deletar Regra')
        }
      }
    },

  },
}
</script>
